import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useParallax } from 'react-scroll-parallax';
import { MOBILE_WIDTH } from '../../constants/constants';
import { useScreenSize } from '../../hooks/useOutsideClick';
import { scrollToPlans } from '../Header';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/language';
const logo = require('../../assets/base2.png');
const bg = require('../../assets/footer.jpg');
const bgMobile = require('../../assets/footerMobile.jpg');

export const Apply = () => {
	const lang = useLanguage();
	const { ref } = useParallax({
		opacity: [2, 0],
	});
	const { ref: ref2 } = useParallax({
		opacity: [0, 1],
		translateY: [50, 0],
		scale: [0.7, 1],
	});
	const { ref: ref3 } = useParallax({
		opacity: [0, 1],
	});
	const { ref: ref4 } = useParallax({
		opacity: [0, 1],
	});
	const { ref: ref5 } = useParallax({
		opacity: [0, 1],
	});
	const screenSize = useScreenSize();
	const isMobileWidth = useMemo(
		() => screenSize.width < MOBILE_WIDTH,
		[screenSize]
	);

	return (
		// @ts-ignore
		<Wrapper>
			<Blur />
			{/* @ts-ignore */}
			<Scrim ref={ref} />
			<Block isMobile={isMobileWidth}>
				{/* @ts-ignore */}
				<div ref={ref2}>
					<img src={logo} alt="logo" />
				</div>
				{/* @ts-ignore */}
				<h1 ref={ref3}>
					{LANG_TEXT[lang].connect.text1} <br /> {LANG_TEXT[lang].connect.text2}
				</h1>
				{/* @ts-ignore */}
				<p ref={ref4}>{LANG_TEXT[lang].connect.text3}</p>
				{/* @ts-ignore */}
				<button ref={ref5} onClick={() => scrollToPlans()}>
					{LANG_TEXT[lang].connect.text4}
				</button>
			</Block>
		</Wrapper>
	);
};

const Blur = styled.div`
	position: absolute;
	z-index: 11;
	width: 100vw;
	height: 300px;
	backdrop-filter: blur(20px);
	margin-top: -100px;
`;

const Scrim = styled.div`
	z-index: 10;
	width: 100vw;
	height: 100vh;
	background-image: linear-gradient(
		to top,
		rgba(255, 255, 255, 0) 0%,
		rgb(255, 255, 255) 100%
	);
`;

const Wrapper = styled.div`
	z-index: 9;
	height: 200vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	margin-top: -80vh;
	z-index: 1;
`;

const Block = styled.div<{ isMobile: boolean }>`
	width: 100%;
	height: 100vh;
	position: sticky;
	bottom: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-image: url(${({ isMobile }) => (isMobile ? bgMobile : bg)});
	background-repeat: no-repeat;
	background-size: cover;

	> div {
		width: 100px;

		img {
			width: 100%;
			border-radius: 23px;
		}
	}

	h1 {
		line-height: 1.1;
		margin-top: 10px;
		letter-spacing: 0em;
		font-size: 90px;
		text-align: center;
	}

	p {
		margin-top: 5px;
		font-size: 38px;
		font-family: 'SF-Bold';
	}

	button {
		margin-top: 40px;
		background: #fff;
		color: black;
		font-family: 'SF-Regular';
		padding: 7px 15px;
		border: none;
		font-size: 17px;
		letter-spacing: -0.022em;
		border-radius: 30px;
		cursor: pointer;

		&:hover {
			background: #efefef;
		}
	}

	@media screen and (max-width: 1170px) {
		h1 {
			font-size: 68px;
		}

		p {
			font-size: 22px;
		}
		> div {
			width: 80px;

			img {
				border-radius: 20px;
			}
		}
	}

	@media screen and (max-width: 837px) {
		background-position: center;

		h1 {
			font-size: 48px;
		}
		p {
			font-size: 20px;
		}

		button {
			margin-top: 30px;
		}

		> div {
			width: 70px;

			img {
				border-radius: 18px;
			}
		}
	}

	@media screen and (max-width: 500px) {
		h1 {
			font-size: 32px;
		}

		p {
			font-size: 16px;
		}

		button {
			font-size: 14px;
			margin-top: 20px;
		}

		> div {
			width: 62px;

			img {
				border-radius: 15px;
			}
		}
	}
`;
