import React, { Fragment, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { BigCard } from '../BigCard';
import { CreatedByFace } from '../CreatedBy/Face';
import { CreatedByBack } from '../CreatedBy/Back';
import { useScreenSize } from '../../hooks/useOutsideClick';
import { MobileCard } from '../MobileCard';
import { MOBILE_WIDTH } from '../../constants/constants';
import { MobileModal } from '../MobileModal';
import { CreatedByMobile } from '../CreatedBy/Mobile';
import { CreatedByModalContent } from '../CreatedBy/ModalContent';
import { SmallCard } from '../SmallCard';
import { IndividualFace } from '../Individual/Face';
import { IndividualBack } from '../Individual/Back';
import { IndividualMobile } from '../Individual/Mobile';
import { IndividualModalContent } from '../Individual/ModalContent';
import { PropFace } from '../Prop/Face';
import { PropBack } from '../Prop/Back';
import { PropMobile } from '../Prop/Mobile';
import { PropModalContent } from '../Prop/ModalContent';
import { JoinFace } from '../Join/Face';
import { JoinMobile } from '../Join/Mobile';
import { TeamFace } from '../Team/Face';
import { TeamBack } from '../Team/Back';
import { TeamMobile } from '../Team/Mobile';
import { TeamModalContent } from '../Team/ModalContent';
import { AnalysisBack } from '../Analysis/Back';
import { AnalysisFace } from '../Analysis/Face';
import { AnalysisMobile } from '../Analysis/Mobile';
import { AnalysisModalContent } from '../Analysis/ModalContent';
import { StreamsFace } from '../Streams/Face';
import { StreamsBack } from '../Streams/Back';
import { StreamsMobile } from '../Streams/Mobile';
import { StreamsModalContent } from '../Streams/ModalContent';
import { TechnicalFace } from '../Technical/Face';
import { TechnicalBack } from '../Technical/Back';
import { TechnicalMobile } from '../Technical/Mobile';
import { TechnicalModalContent } from '../Technical/ModalContent';
import { MorningFace } from '../Morning/Face';
import { MorningBack } from '../Morning/Back';
import { MorningMobile } from '../Morning/Mobile';
import { MorningModalContent } from '../Morning/ModalContent';
import { LiveFace } from '../Live/Face';
import { LiveBack } from '../Live/Back';
import { LiveMobile } from '../Live/Mobile';
import { LiveModalContent } from '../Live/ModalContent';
import { Apply } from '../Apply';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/language';

export const Main = () => {
	const lang = useLanguage();
	const screenSize = useScreenSize();
	const isMobileWidth = useMemo(
		() => screenSize.width < MOBILE_WIDTH,
		[screenSize]
	);
	const scrollRef = useRef(null);

	const executeScroll = () => (scrollRef as any).current.scrollIntoView();

	const [isCreatedByOpen, setIsCreatedByOpen] = useState(false);
	const [isCreatedByModalOpen, setIsCreatedByModalOpen] = useState(false);

	const [isIndividualOpen, setIsIndividualOpen] = useState(false);
	const [isIndividualModalOpen, setIsIndividualModalOpen] = useState(false);

	const [isPropOpen, setIsPropOpen] = useState(false);
	const [isPropModalOpen, setIsPropModalOpen] = useState(false);

	const [, setIsJoinOpen] = useState(false);

	const [isTeamOpen, setIsTeamOpen] = useState(false);
	const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);

	const [isAnalysisOpen, setIsAnalysisOpen] = useState(false);
	const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false);

	const [isStreamsOpen, setIsStreamsOpen] = useState(false);
	const [isStreamsModalOpen, setIsStreamsModalOpen] = useState(false);

	const [isTechnicalOpen, setIsTechnicalOpen] = useState(false);
	const [isTechnicalModalOpen, setIsTechnicalModalOpen] = useState(false);

	const [isMorningOpen, setIsMorningOpen] = useState(false);
	const [isMorningModalOpen, setIsMorningModalOpen] = useState(false);

	const [isLiveOpen, setIsLiveOpen] = useState(false);
	const [isLiveModalOpen, setIsLiveModalOpen] = useState(false);

	const CardsContent = (
		<CardsWrapper>
			{isMobileWidth ? (
				<>
					{/* Created By - 1st block */}
					<MobileCard
						content={<CreatedByMobile />}
						onClick={() => setIsCreatedByModalOpen(true)}
					/>
					<MobileModal
						isOpen={isCreatedByModalOpen}
						close={() => setIsCreatedByModalOpen(false)}
						content={<CreatedByModalContent />}
					/>
					{/* Created By - 1st block */}
					{/* Individual - 2nd block */}
					<MobileCard
						content={<IndividualMobile />}
						onClick={() => setIsIndividualModalOpen(true)}
					/>
					<MobileModal
						isOpen={isIndividualModalOpen}
						close={() => setIsIndividualModalOpen(false)}
						content={<IndividualModalContent />}
					/>
					{/* Individual - 2nd block */}
					{/* Prop - 3rd block */}
					<MobileCard
						content={<PropMobile />}
						onClick={() => setIsPropModalOpen(true)}
					/>
					<MobileModal
						isOpen={isPropModalOpen}
						close={() => setIsPropModalOpen(false)}
						content={<PropModalContent />}
					/>
					{/* Prop - 3rd block */}
					{/* Join - 4th block */}
					<MobileCard content={<JoinMobile />} onClick={executeScroll} />
					{/* Join - 4th block */}
					{/* Team - 5th block */}
					<div ref={scrollRef} />
					<MobileCard
						content={<TeamMobile />}
						onClick={() => setIsTeamModalOpen(true)}
					/>
					<MobileModal
						isOpen={isTeamModalOpen}
						close={() => setIsTeamModalOpen(false)}
						content={<TeamModalContent />}
					/>
					{/* Team - 5th block */}
					{/* Analysis - 6th block */}
					<MobileCard
						content={<AnalysisMobile />}
						onClick={() => setIsAnalysisModalOpen(true)}
					/>
					<MobileModal
						isOpen={isAnalysisModalOpen}
						close={() => setIsAnalysisModalOpen(false)}
						content={<AnalysisModalContent />}
					/>
					{/* Analysis - 6th block */}
					{/* Streams - 7th block */}
					<MobileCard
						content={<StreamsMobile />}
						onClick={() => setIsStreamsModalOpen(true)}
					/>
					<MobileModal
						isOpen={isStreamsModalOpen}
						close={() => setIsStreamsModalOpen(false)}
						content={<StreamsModalContent />}
					/>
					{/* Streams - 7th block */}
					{/* Technical - 8th block */}
					<MobileCard
						content={<TechnicalMobile />}
						onClick={() => setIsTechnicalModalOpen(true)}
					/>
					<MobileModal
						isOpen={isTechnicalModalOpen}
						close={() => setIsTechnicalModalOpen(false)}
						content={<TechnicalModalContent />}
					/>
					{/* Technical - 8th block */}
					{/* Morning - 9th block */}
					<MobileCard
						content={<MorningMobile />}
						onClick={() => setIsMorningModalOpen(true)}
					/>
					<MobileModal
						isOpen={isMorningModalOpen}
						close={() => setIsMorningModalOpen(false)}
						content={<MorningModalContent />}
					/>
					{/* Morning - 9th block */}
					{/* Live - 10th block */}
					<MobileCard
						content={<LiveMobile />}
						onClick={() => setIsLiveModalOpen(true)}
					/>
					<MobileModal
						isOpen={isLiveModalOpen}
						close={() => setIsLiveModalOpen(false)}
						content={<LiveModalContent />}
					/>
					{/* Live - 10th block */}
				</>
			) : (
				<>
					{/* Created By - 1st block */}
					<BigCard
						isTop
						face={<CreatedByFace />}
						back={<CreatedByBack setOpen={setIsCreatedByOpen} />}
						setOpen={setIsCreatedByOpen}
						isOpen={isCreatedByOpen}
					/>
					{/* Created By - 1st block */}
					<SmallCardsWrapper>
						{/* Individual - 2nd block */}
						<SmallCard
							face={<IndividualFace setOpen={setIsIndividualOpen} />}
							back={<IndividualBack setOpen={setIsIndividualOpen} />}
							setOpen={setIsIndividualOpen}
							isOpen={isIndividualOpen}
						/>
						{/* Individual - 2nd block */}
						{/* Prop - 3rd block */}
						<SmallCard
							animationDelay
							face={<PropFace setOpen={setIsPropOpen} />}
							back={<PropBack setOpen={setIsPropOpen} />}
							setOpen={setIsPropOpen}
							isOpen={isPropOpen}
						/>
						{/* Prop - 3rd block */}
					</SmallCardsWrapper>
					{/* Join - 4th block */}
					<BigCard
						face={<JoinFace />}
						back={<h1>навіщо ти лазиш в коді елементу?</h1>}
						onClick={executeScroll}
						setOpen={setIsJoinOpen}
						isOpen={false}
					/>
					{/* Join - 4th block */}
					<SmallCardsWrapper ref={scrollRef}>
						{/* Individual - 5th block */}
						<SmallCard
							face={<TeamFace setOpen={setIsTeamOpen} />}
							back={<TeamBack setOpen={setIsTeamOpen} />}
							setOpen={setIsTeamOpen}
							isOpen={isTeamOpen}
						/>
						{/* Individual - 5th block */}
						{/* Analysis - 6th block */}
						<SmallCard
							animationDelay
							face={<AnalysisFace setOpen={setIsAnalysisOpen} />}
							back={<AnalysisBack setOpen={setIsAnalysisOpen} />}
							setOpen={setIsAnalysisOpen}
							isOpen={isAnalysisOpen}
						/>
						{/* Analysis - 6th block */}
					</SmallCardsWrapper>
					{/* Streams - 7th block */}
					<BigCard
						face={<StreamsFace />}
						back={<StreamsBack setOpen={setIsStreamsOpen} />}
						setOpen={setIsStreamsOpen}
						isOpen={isStreamsOpen}
					/>
					{/* Streams - 7th block */}
					{/* Technical - 8th block */}
					<BigCard
						face={<TechnicalFace />}
						back={<TechnicalBack setOpen={setIsTechnicalOpen} />}
						setOpen={setIsTechnicalOpen}
						isOpen={isTechnicalOpen}
					/>
					{/* Technical - 8th block */}
					<SmallCardsWrapper>
						{/* Morning - 9th block */}
						<SmallCard
							face={<MorningFace setOpen={setIsMorningOpen} />}
							back={<MorningBack setOpen={setIsMorningOpen} />}
							setOpen={setIsMorningOpen}
							isOpen={isMorningOpen}
						/>
						{/* Morning - 9th block */}
						{/* Live - 10th block */}
						<SmallCard
							animationDelay
							face={<LiveFace setOpen={setIsLiveOpen} />}
							back={<LiveBack setOpen={setIsLiveOpen} />}
							setOpen={setIsLiveOpen}
							isOpen={isLiveOpen}
						/>
						{/* Live - 10th block */}
					</SmallCardsWrapper>
				</>
			)}
		</CardsWrapper>
	);

	return (
		<Wrapper>
			<p>{LANG_TEXT[lang].action}</p>
			{CardsContent}
			<Apply />
		</Wrapper>
	);
};

const SmallCardsWrapper = styled.div`
	display: flex;

	> div:nth-of-type(2) {
		margin-left: 10px;
	}
`;

const CardsWrapper = styled.div`
	position: relative;
	z-index: 2;
	max-width: 1180px;
	padding: 0 20px 100px;
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 100vh;
	background: rgb(255, 255, 255);
	background: -moz-linear-gradient(
		90deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(235, 235, 235, 1) 50%,
		rgba(255, 255, 255, 1) 100%
	);
	background: -webkit-linear-gradient(
		90deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(235, 235, 235, 1) 50%,
		rgba(255, 255, 255, 1) 100%
	);
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(235, 235, 235, 1) 50%,
		rgba(255, 255, 255, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

	> p {
		padding: 100px;
		text-align: center;
		font-size: 32px;
		width: 1011px;
	}

	@media screen and (max-width: 1170px) {
		> p {
			font-size: 24px;
			width: 800px;
		}
	}

	@media screen and (max-width: ${MOBILE_WIDTH - 1}px) {
		> p {
			padding: 80px 0;
			width: 350px;
			font-size: 21px;
			line-height: 1.381;
			letter-spacing: 0.011em;
		}
	}
`;
