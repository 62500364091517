import React from 'react';
import { AWP } from './AWP';
import styled from 'styled-components';
import { fadeInDown, fadeInUp } from '../../styles/animations';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/language';
const computer = require('../../assets/research/computer.png');

export const Heading = () => {
	const lang = useLanguage();

	return (
		<Wrap>
			<ImgWrap>
				<img src={computer} alt="computer" />
			</ImgWrap>
			<Logo>
				<div>
					<AWP color="#c82626" />
				</div>
				<p>GS Club Research</p>
			</Logo>

			<Info>
				<h1>Data. Research.</h1>
				<h1>
					<span>Expectations.</span> Reality and statistics.
				</h1>
				<p>{LANG_TEXT[lang].research.heading.text1}</p>
				<p>{LANG_TEXT[lang].research.heading.text2}</p>
			</Info>
		</Wrap>
	);
};

const Wrap = styled.div`
	background: #f5f5f7;
	padding-top: 52px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px 185px;

	@media screen and (max-width: 600px) {
		padding: 52px 20px 64px;
	}
`;

const ImgWrap = styled.div`
	margin-top: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	animation: ${fadeInDown} 0.8s cubic-bezier(0.42, 0, 0.58, 1) forwards;

	@media screen and (max-width: 600px) {
		margin-top: 30px;
	}

	img {
		max-width: 800px;

		@media screen and (max-width: 1400px) {
			max-width: 600px;
		}

		@media screen and (max-width: 1200px) {
			max-width: 500px;
		}

		@media screen and (max-width: 550px) {
			max-width: 100%;
		}
	}
`;

const Logo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	animation: ${fadeInUp} 0.55s cubic-bezier(0.42, 0, 0.58, 1) forwards;

	> div {
		width: 100px;

		@media screen and (max-width: 600px) {
			width: 60px;
		}

		svg {
			width: 100%;
		}
	}

	p {
		font-weight: bold;
		font-size: 21px;
		margin-left: 8px;
		margin-top: -5px;

		@media screen and (max-width: 600px) {
			font-size: 16px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	animation: ${fadeInUp} 0.55s cubic-bezier(0.42, 0, 0.58, 1) forwards;

	h1 {
		font-size: 80px;
		text-align: center;

		span {
			text-decoration: line-through;
		}

		@media screen and (max-width: 600px) {
			font-size: 40px;
		}
	}

	p {
		margin-top: 35px;
		font-size: 21px;
		color: #86868b;
		text-align: center;
		max-width: 800px;

		@media screen and (max-width: 600px) {
			width: 100%;
			font-size: 17px;
			text-align: left;
			padding: 0 30px;
		}
	}
`;
