/* eslint-disable array-callback-return */
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card } from './Card';
import { Cart } from './Cart';
import { LANG_TEXT } from '../../lang/language';
import { useLanguage } from '../../hooks/useLanguage';
const eur = require('../../assets/research/eur.png');
const cad = require('../../assets/research/cad.png');
const jpy = require('../../assets/research/jpy.png');
const gbp = require('../../assets/research/gbp.png');
const chf = require('../../assets/research/chf.png');
const aud = require('../../assets/research/aud.png');

const xau = require('../../assets/research/xau.png');
const xag = require('../../assets/research/xag.png');
const oil = require('../../assets/research/oil.png');

const spx = require('../../assets/research/spx.png');
const nas = require('../../assets/research/nas.png');
const ger = require('../../assets/research/ger.png');
const dj = require('../../assets/research/dj.png');

export const List = () => {
	const lang = useLanguage();
	const [eurusd, setEurusd] = useState(false);
	const [usdcad, setUsdcad] = useState(false);
	const [usdjpy, setUsdjpy] = useState(false);
	const [gbpusd, setGbpusd] = useState(false);
	const [usdchf, setUsdchf] = useState(false);
	const [audusd, setAudusd] = useState(false);

	const [xauusd, setXauusd] = useState(false);
	const [xagusd, setXagusd] = useState(false);
	const [oilusd, setOilusd] = useState(false);

	const [spx500, setSpx500] = useState(false);
	const [dj30, setDj30] = useState(false);
	const [nas100, setNas100] = useState(false);
	const [ger40, setGer40] = useState(false);

	const counter = useMemo(
		() =>
			[
				eurusd,
				usdcad,
				usdjpy,
				gbpusd,
				usdchf,
				audusd,
				xauusd,
				xagusd,
				oilusd,
				spx500,
				dj30,
				nas100,
				ger40,
			].filter((k) => {
				if (k) return k;
			}).length,
		[
			eurusd,
			usdcad,
			usdjpy,
			gbpusd,
			usdchf,
			audusd,
			xauusd,
			xagusd,
			oilusd,
			spx500,
			dj30,
			nas100,
			ger40,
		]
	);

	return (
		<>
			<Cart
				counter={counter}
				eurusd={eurusd}
				setEurusd={() => setEurusd(false)}
				usdcad={usdcad}
				setUsdcad={() => setUsdcad(false)}
				usdjpy={usdjpy}
				setUsdjpy={() => setUsdjpy(false)}
				gbpusd={gbpusd}
				setGbpusd={() => setGbpusd(false)}
				usdchf={usdchf}
				setUsdchf={() => setUsdchf(false)}
				audusd={audusd}
				setAudusd={() => setAudusd(false)}
				xauusd={xauusd}
				setXauusd={() => setXauusd(false)}
				xagusd={xagusd}
				setXagusd={() => setXagusd(false)}
				oilusd={oilusd}
				setOilusd={() => setOilusd(false)}
				spx500={spx500}
				setSpx500={() => setSpx500(false)}
				dj30={dj30}
				setDj30={() => setDj30(false)}
				nas100={nas100}
				setNas100={() => setNas100(false)}
				ger40={ger40}
				setGer40={() => setGer40(false)}
			/>
			<Wrap>
				<h2>Experience</h2>

				<h1> {LANG_TEXT[lang].research.list.text1}</h1>
				<section>
					<p>{LANG_TEXT[lang].research.list.text2}</p>
					<p>{LANG_TEXT[lang].research.list.text3}</p>
				</section>

				<CardsWrapper>
					<h3>Forex.</h3>
					<Cards>
						<Card
							onClick={() => setEurusd(!eurusd)}
							isInCart={eurusd}
							ticker="EURUSD"
							category="Forex"
							bgColor="#1e4b29"
							textColor="white"
							image={eur}
						/>
						<Card
							onClick={() => setUsdcad(!usdcad)}
							isInCart={usdcad}
							ticker="USDCAD"
							category="Forex"
							bgColor="#23404a"
							textColor="white"
							image={cad}
						/>
						<Card
							onClick={() => setUsdjpy(!usdjpy)}
							isInCart={usdjpy}
							ticker="USDJPY"
							category="Forex"
							bgColor="#ffb7e1"
							textColor="#5b480d"
							image={jpy}
						/>
						<Card
							onClick={() => setGbpusd(!gbpusd)}
							isInCart={gbpusd}
							ticker="GBPUSD"
							category="Forex"
							bgColor="#242424"
							textColor="white"
							image={gbp}
						/>
						<Card
							onClick={() => setUsdchf(!usdchf)}
							isInCart={usdchf}
							ticker="USDCHF"
							category="Forex"
							bgColor="#c82626"
							textColor="#dfdbc9"
							image={chf}
						/>
						<Card
							onClick={() => setAudusd(!audusd)}
							isInCart={audusd}
							ticker="AUDUSD"
							category="Forex"
							bgColor="#090909"
							textColor="#dcc6a1"
							image={aud}
						/>
					</Cards>

					<h3>Commodities.</h3>
					<Cards>
						<Card
							onClick={() => setXauusd(!xauusd)}
							isInCart={xauusd}
							ticker="XAUUSD"
							category="Metals"
							bgColor="#2100ac"
							textColor="#fde568"
							image={xau}
							imgCustomWidth={62}
						/>
						<Card
							onClick={() => setXagusd(!xagusd)}
							isInCart={xagusd}
							ticker="XAGUSD"
							category="Metals"
							bgColor="#c2c2c2"
							textColor="#000100"
							image={xag}
							imgCustomWidth={50}
						/>
						<Card
							onClick={() => setOilusd(!oilusd)}
							isInCart={oilusd}
							ticker="OIL"
							category="Oil"
							bgColor="#df0100"
							textColor="#000100"
							image={oil}
						/>
					</Cards>

					<h3>Indices.</h3>
					<Cards>
						<Card
							onClick={() => setSpx500(!spx500)}
							isInCart={spx500}
							ticker="SPX500"
							category="Indices"
							bgColor="#012769"
							textColor="white"
							image={spx}
						/>
						<Card
							onClick={() => setDj30(!dj30)}
							isInCart={dj30}
							ticker="DJ30"
							category="Indices"
							bgColor="#f5f5f7"
							textColor="#16a3d8"
							image={dj}
						/>
						<Card
							onClick={() => setNas100(!nas100)}
							isInCart={nas100}
							ticker="NAS100"
							category="Indices"
							bgColor="#1492c1"
							textColor="white"
							image={nas}
						/>
						<Card
							onClick={() => setGer40(!ger40)}
							isInCart={ger40}
							ticker="GER40"
							category="Indices"
							bgColor="#c2c2c2"
							textColor="black"
							image={ger}
						/>
					</Cards>
				</CardsWrapper>
			</Wrap>
		</>
	);
};

const Wrap = styled.div`
	background: #fff;
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 185px 20px 100px;

	@media screen and (max-width: 600px) {
		padding: 64px 27px 100px;
	}

	> h1 {
		font-size: 80px;
		text-align: center;

		@media screen and (max-width: 600px) {
			font-size: 40px;
			text-align: left;
		}
	}

	> section {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		> p {
			margin-top: 35px;
			font-size: 21px;
			color: #86868b;
			text-align: center;
			max-width: 800px;

			@media screen and (max-width: 600px) {
				width: 100%;
				font-size: 17px;
				text-align: left;
			}
		}
	}

	h2 {
		font-size: 21px;
		margin-bottom: 12px;

		@media screen and (max-width: 600px) {
			font-size: 17px;
			text-align: left;
		}
	}

	h3 {
		margin-top: 80px;
		margin-bottom: 10px;
		margin-left: 5px;
		font-size: 33px;
		text-align: left;
		width: 1082px;

		@media screen and (max-width: 1170px) {
			width: 100%;
		}

		@media screen and (max-width: 850px) {
			text-align: center;
			margin-left: 0;
		}
	}
`;

const Cards = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 1082px;

	@media screen and (max-width: 1170px) {
		width: 100%;
	}

	@media screen and (max-width: 763px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	> div {
		margin: 5px;

		@media screen and (max-width: 763px) {
			margin: 5px 0;
		}
	}
`;

const CardsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
