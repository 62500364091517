import React, { FC } from 'react';
import styled from 'styled-components';
import { LANG_TEXT } from '../../lang/language';
import { useLanguage } from '../../hooks/useLanguage';
const plus = require('../../assets/PlusBlack.png');
const base1 = require('../../assets/base1.png');
const base2 = require('../../assets/base2.png');
const base3 = require('../../assets/base3.png');
const base4 = require('../../assets/base4.png');

type TechnicalBackProps = {
	setOpen: (value: boolean) => void;
};

export const TechnicalBack: FC<TechnicalBackProps> = ({ setOpen }) => {
	const lang = useLanguage();
	return (
		<Wrap>
			<article>
				<Heading>{LANG_TEXT[lang].cards.tech.heading}</Heading>
				<p>{LANG_TEXT[lang].cards.tech.text}</p>
			</article>
			<section>
				<div>
					<div>
						<img src={base1} alt="base1" />
					</div>
					<div>
						<img src={base2} alt="base2" />
					</div>
				</div>
				<div>
					<div>
						<img src={base3} alt="base3" />
					</div>
					<div>
						<img src={base4} alt="base4" />
					</div>
				</div>
			</section>
			<div onClick={() => setOpen(false)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	height: 820px;
	width: 1140px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	p {
		width: 390px;
		margin-top: 22px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}

	section {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		width: 300px;
		height: 300px;

		div {
			display: flex;
			justify-content: space-between;

			div {
				width: 125px;

				img {
					width: 100%;
				}
			}
		}
	}

	> div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			transform: rotate(45deg);
			width: 100%;

			&:hover {
				opacity: 0.5;
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 810px;

		section {
			width: 200px;
			height: 200px;

			div div {
				width: 85px;

				img {
					width: 100%;
				}
			}
		}

		p {
			width: 300px;
			font-size: 13px;
		}
	}
`;

const Heading = styled.h2`
	font-size: 21px;
	line-height: 1.381;
	font-weight: 400;
	letter-spacing: 0.015em;
	color: #656565;
	font-family: 'SF-Bold';

	@media screen and (max-width: 1170px) {
		font-size: 13px;
	}
`;
