export const LANGUAGE_OPTIONS = ['ua', 'ru'];

export const LANG_TEXT = {
	ua: {
		start: {
			text1: 'Розкрий свій трейдерський потенціал.',
			text2: 'Разом з GoShawty Club.',
			text3: 'Стань учасником',
		},
		action: `Запорука успіху - дії. У GS Club ми працюємо над тим, щоб створити
				сильне оточення навколо нас. Усі трейдери навчаються один у одного та
				обмінюються інформацією про нові інструменти чи ринки - це безперервний
				процес розвитку для кожного члена спільноти. Ви отримуєте можливість
				швидко вдосконалювати свої навички та виправляти помилки.`,
		cards: {
			individual: {
				heading: 'Індивідуальні консультації',
				heading2: 'з менторами',
				text: `У GS Club ми націлені на ваш персональний розвиток. Кожен учасник має
				можливість двічі на місяць отримати індивідуальну консультацію від
				ментора тривалістю 20-30 хвилин. Наші досвідчені члени команди готові
				поділитися знаннями та стратегіями, які допоможуть вам досягти нових
				висот у трейдингу та допомогти виправити помилки. Незалежно від рівня
				вашого досвіду — ми сприятимемо вашому успіху.`,
			},
			prop: {
				heading: 'Можливість отримати проп-акаунт',
				heading2: 'за активність',
				text: `Ми цінуємо проактивність та відданість своїй справі. Найактивніші й
				найперспективніші учасники нашої спільноти мають змогу отримати
				проп-аккаунти та можливість торгувати з більшим капіталом, мінімізуючи
				власні ризики. Приєднуйтесь до GS Club спільноти, і ми підтримуватимемо
				вас на кожному кроці!`,
			},
			team: {
				heading: 'Обмін ідеями та досвідом',
				text: `Працюйте пліч-о-пліч з іншими трейдерами та менторами в середовищі, де
				усі націлені на успіх.`,
			},
			analysis: {
				heading: 'Щоденний ринковий аналіз',
				text: `Будьте завжди на крок попереду з нашим детальним аналізом ринку від
				менторів, де ми висвітлюємо актуальні ситуації та потенційні можливості
				для торгівлі. Breakfast Call з аналізом золота та форекс пар - щоранку о 9:45 по Києву. Opening Call з аналізом індексів і золота - щоденно о 16:25 по Києву.`,
			},
			streams: {
				heading: 'Тематичні стріми',
				text: `Приймайте участь у стрімах на різні теми — від створення стратегій та
					розбору інструментів до розвитку психологічної стійкості. Понад 12
					годин на місяц для покращення вашої торгівлі!
				`,
			},
			tech: {
				heading: 'База технічних знань',
				text: `Отримайте доступ до нашої бібліотеки, повної корисних уроків, гайдів
					та ресурсів, які допоможуть вам стати професіоналом. До вашого
					користування зручні індикатори від GoShawty для покращення вашого
					торгового процесу.`,
			},
			morning: {
				heading: 'Брекфаст колли та розбори тижня',
				text: `Розпочинайте день зі швидкого ранкового стріму з аналізом ринку.
				Завершуйте тиждень нашими оглядами — тут ми розглядаємо ключові події,
				що вплинули на ринок, та аналізуємо, що працювало найкраще, розбираємо
				помилки учасників та даємо рекомендації.`,
			},
			live: {
				heading: 'Лайв трейдинг',
				text: `Спостерігайте за досвідченими трейдерами в дії, дізнавайтеся про їх
				стратегії та рішення в режимі реального часу.`,
			},
		},
		connect: {
			text1: 'Розпочни свій ',
			text2: 'шлях з GoShawty Club.',
			text3: 'Подай заявку і ми звʼяжемось з тобою.',
			text4: 'Стати учасником',
		},
		subscription: {
			heading: 'Обери свою підписку.',
			products: {
				lifetime: {
					name: 'GS Club Lifetime Access',
					info: 'Разова оплата',
				},
				month3: {
					name: 'GS Club - 3 Місяці',
					info: '$199 - перших 3 місяці',
				},
				month1: {
					name: 'GS Club - 1 Місяць',
					info: '$79 - перший місяць',
				},
				pulse: {
					name: 'GoShawty Pulse',
					info: 'Доступ по аналітики GoShawty',
				},
			},
		},
		research: {
			heading: {
				text1: `Ми зібрали статистику за останніх 5 років по найпопулярнішим активам
					серед трейдерів. Починаючи з EUR і XAU та закінчуючи US100 та GER40.
					Дані, які ви отримуєте, відкривають вам очі на те чому конкретний
					актив розвертається/продовжує рух в тому чи іншому місці. А також
					багато всього іншого. В комплекті до кожного ресерчу йде безкоштовна
					відео-інструкція по використанню метрик.`,
				text2: 'Дані за 5 років по 1 активу - $20',
			},
			list: {
				text1: 'Що всередині?',
				text2: `Хай і лоу тижня по дням, потенціал руху в $ на кожен день тижня,
						робота з розширенням Initial Balance: потенціал пробиття 1 або 2
						боків, розширення 25/50/100% від розміру IB, статистика зняття хаїв
						та лоїв попередніх днів і тижнів, Opening Week Range, вірогідність
						Inside Bar. Та багато іншого.`,
				text3: `Спробувавши працювати зі статистичними даними один раз - ви назавжди
						захочете працювати з ними і далі, так як ніщо не відкриває карти
						так, як це роблять реальні цифри за минулі роки. Більше не потрібно
						здогадуватись - скористайтесь нашими ресерчами і самі все
						зрозумієте.`,
				text4: 'Статистика за 5 років',
				text5: 'Відео-інструкція',
			},
			connect: {
				text1: 'Не знайшли актив або метрику,',
				text2: 'які вас цікавлять?',
				text3: 'Напиши нам і ми зберемо дані спеціально для тебе.',
				text4: 'Звʼязок',
			},
		},
	},
	ru: {
		start: {
			text1: 'Раскрой свой трейдерский потенциал.',
			text2: 'Вместе с GoShawty Club.',
			text3: 'Стань участником',
		},
		action: `Залог успеха – действие. В GS Club мы работаем над тем, чтобы создать
                сильное окружение вокруг нас. Все трейдеры учатся друг у друга и
                обмениваются информацией о новых инструментах или рынках - это непрерывный
                процесс развития каждого члена сообщества. Вы получаете возможность
                быстро совершенствовать свои навыки и исправлять ошибки.`,
		cards: {
			individual: {
				heading: 'Индивидуальные консультации',
				heading2: 'с менторами',
				text: `В GS Club мы нацелены на ваше персональное развитие. Каждый участник имеет
                возможность дважды в месяц получить индивидуальную консультацию от
                ментора продолжительностью 20-30 минут. Наши опытные члены команды готовы
                поделиться знаниями и стратегиями, которые помогут вам достичь новых
                высот в трейдинге и помочь исправить ошибки. Независимо от уровня
                вашего опыта – мы будем способствовать вашему успеху.`,
			},
			prop: {
				heading: 'Возможность получить проп-аккаунт',
				heading2: 'за активность',
				text: `Мы ценим проактивность и преданность своему делу. Самые активные и
                самые перспективные участники нашего сообщества могут получить
                проп-аккаунты и возможность торговать с большим капиталом, минимизируя
                собственные риски. Присоединяйтесь к GS Club сообществу, и мы будем поддерживать
                вас на каждом шагу!`,
			},
			team: {
				heading: 'Обмен идеями и опытом',
				text: `Работайте бок о бок с другими трейдерами и менторами в среде, где все нацелены на успех.`,
			},
			analysis: {
				heading: 'Ежедневный утренний анализ',
				text: `Будьте всегда на шаг впереди с нашим детальным анализом рынка от
                менторов, где мы освещаем актуальные ситуации и потенциальные возможности
                для торговли. Breakfast Call с анализом золота и форекс пар - каждое утро в 9:45 по Киеву. Opening Call с анализом индексов и золота - ежедневно в 16:25 по Киеву.`,
			},
			streams: {
				heading: 'Тематические стримы',
				text: `Принимайте участие в стримах на различные темы — от создания стратегий и
                разбора инструментов к развитию психологической стойкости. Более 12
                часов в месяц для улучшения вашей торговли!`,
			},
			tech: {
				heading: 'База технических знаний',
				text: `Получите доступ к нашей библиотеке, полной полезных уроков, гайдов
                и ресурсы, которые помогут вам стать профессионалом. К вашему
                пользованию удобные индикаторы от GoShawty для улучшения вашего
                торгового процесса.`,
			},
			morning: {
				heading: 'Брекфаст коллы и разборы недели',
				text: `Начните день с быстрого утреннего стрима с анализом рынка.
                Завершайте неделю нашими обзорами – здесь мы рассматриваем ключевые события,
                что повлияли на рынок, и анализируем, что работало лучше всего, разбираем
                ошибки участников и даем рекомендации.`,
			},
			live: {
				heading: 'Лайв трейдинг',
				text: `Наблюдайте за опытными трейдерами в действии, узнайте о их
                стратегиях и решениях в режиме реального времени.`,
			},
		},
		connect: {
			text1: 'Начни свой',
			text2: 'путь с GoShawty Club.',
			text3: 'Оставляй заявку и мы свяжемся с тобой.',
			text4: 'Стать участником',
		},
		subscription: {
			heading: 'Выбери свою подписку.',
			products: {
				lifetime: {
					name: 'GS Club Lifetime Access',
					info: 'Разовая оплата',
				},
				month3: {
					name: 'GS Club - 3 Месяца',
					info: '$199 - первых 3 месяца',
				},
				month1: {
					name: 'GS Club - 1 Месяц',
					info: '$79 - первый месяц',
				},
				pulse: {
					name: 'GoShawty Pulse',
					info: 'Доступ к аналитике GoShawty',
				},
			},
		},
		research: {
			heading: {
				text1: `Мы собрали статистику за последние 5 лет по самым популярным активам
				среди трейдеров. Начиная с EUR и XAU и заканчивая US100 и GER40.
				Данные, которые вы получаете, открывают вам глаза на то, почему конкретный актив разворачивается/продолжает движение в том или ином месте. А также
				многое другое. В комплекте к каждому ресерчу идет бесплатная
				видео-инструкция по использованию метрик.`,
				text2: 'Данные за 5 лет по 1 активу – $20',
			},
			list: {
				text1: 'Что внутри?',
				text2: `Хай и лоу недели по дням, потенциал движения в $ на каждый день недели,
					работа с расширением Initial Balance: потенциал пробития 1 или 2
					сторон, расширение 25/50/100% от размера IB, статистика снятия хаев
					и лоев предыдущих дней и недель, Opening Week Range, вероятность
					Inside Bar. И многое другое.`,
				text3: `Попробовав работать со статистическими данными один раз – вы навсегда
					захотите работать с ними и дальше, так как ничто не открывает карты
					как это делают реальные цифры за прошлые годы. Больше не нужно
					догадываться – воспользуйтесь нашими ресерчами и сами все
					поймете.`,
				text4: 'Статистика за 5 лет',
				text5: 'Видео-инструкция',
			},
			connect: {
				text1: 'Не нашли актив либо метрику,',
				text2: 'которые вас интересуют?',
				text3: 'Напиши нам и мы соберем данные специально для тебя.',
				text4: 'Связь',
			},
		},
	},
};
