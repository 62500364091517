import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/language';

export const IndividualModalContent = () => {
	const lang = useLanguage();

	return (
		<Wrap>
			<h1>
				{LANG_TEXT[lang].cards.individual.heading}{' '}
				{LANG_TEXT[lang].cards.individual.heading2}
			</h1>
			<p>{LANG_TEXT[lang].cards.individual.text}</p>
		</Wrap>
	);
};

const Wrap = styled.div`
	h1 {
		font-size: 21px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.015em;
		color: #656565;
		font-family: 'SF-Bold';
	}
	p {
		margin-top: 20px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}
`;
