import React, { useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router';
import styled from 'styled-components';
import { fadeInDown } from '../../styles/animations';
import { useLanguage } from '../../hooks/useLanguage';

export const scrollToPlans = () => {
	const element = document.getElementById('plans');
	element?.scrollIntoView();
};

export const relocate = (url: string) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
	if (newWindow) newWindow.opener = null;
};

export const Header = () => {
	const navigate = useNavigate();
	const lang = useLanguage();
	const { pathname } = useLocation();

	useEffect(() => {
		if (
			pathname !== '/' &&
			pathname !== '/research' &&
			pathname !== '/ru/research' &&
			pathname !== '/ru'
		)
			navigate('/');
	}, [navigate, pathname]);

	return (
		<Wrap>
			<div>
				<p>GoShawty Club</p>
				<div>
					<NavLink
						to={lang === 'ua' ? '/' : '/ru'}
						className={({ isActive }) => (isActive ? 'active' : '')}
						end
					>
						Club
					</NavLink>
					<NavLink
						to={lang === 'ua' ? '/research' : '/ru/research'}
						className={({ isActive }) => (isActive ? 'active' : '')}
					>
						Research
					</NavLink>

					<p onClick={() => relocate('https://goshawty.net/indicators')}>
						Indicators
					</p>
					<p onClick={() => relocate('https://t.me/+40hgtZ2jmvNhMmEy')}>
						Telegram
					</p>
					<StyledButton
						onClick={() => {
							if (pathname === '/ru/research') {
								navigate('/ru');
							}
							if (pathname === '/research') {
								navigate('/');
							}
							if (pathname === '/ru') {
								setTimeout(() => scrollToPlans(), 100);
							}
							if (pathname === '/') {
								setTimeout(() => scrollToPlans(), 100);
							}
						}}
					>
						Join GS Club
					</StyledButton>
					<p
						style={{ fontWeight: 'bold' }}
						onClick={() => {
							if (lang === 'ua') {
								if (pathname === '/research') {
									navigate('/ru/research');
								} else {
									navigate('/ru');
								}
							}
							if (lang === 'ru') {
								if (pathname === '/ru/research') {
									navigate('/research');
								} else {
									navigate('/');
								}
							}
						}}
					>
						{lang === 'ua' ? 'RU' : 'UA'}
					</p>
				</div>
				<section>
					<StyledButton
						onClick={() =>
							navigate(lang === 'ua' ? '/research' : '/ru/research')
						}
						isMobile
						isWhite
					>
						Research
					</StyledButton>
					<StyledButton
						onClick={() => {
							if (pathname === '/ru/research') {
								navigate('/ru');
							}
							if (pathname === '/research') {
								navigate('/');
							}
							if (pathname === '/ru') {
								setTimeout(() => scrollToPlans(), 100);
							}
							if (pathname === '/') {
								setTimeout(() => scrollToPlans(), 100);
							}
						}}
						isMobile
					>
						Join GS Club
					</StyledButton>
					<p
						onClick={() => {
							if (lang === 'ua') {
								if (pathname === '/research') {
									navigate('/ru/research');
								} else {
									navigate('/ru');
								}
							}
							if (lang === 'ru') {
								if (pathname === '/ru/research') {
									navigate('/research');
								} else {
									navigate('/');
								}
							}
						}}
					>
						{lang === 'ua' ? 'RU' : 'UA'}
					</p>
				</section>
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	> div {
		width: 1000px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: 1170px) {
			width: 100%;
			padding: 0 45px;
		}

		@media screen and (max-width: 800px) {
			padding: 0 16px;
		}

		> p {
			font-size: 20px;
		}

		> section {
			display: flex;
			align-items: center;

			> p {
				margin-left: 10px;
			}

			@media screen and (min-width: 700px) {
				display: none;
			}
		}

		> div {
			width: 385px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 100%;

			@media screen and (max-width: 700px) {
				display: none;
			}

			> p,
			a {
				margin-top: 2px;
				display: flex;
				align-items: center;
				height: 100%;
				font-size: 12px;
				font-family: 'SF-Regular';
				color: #00000099;
				cursor: pointer;
				text-decoration: none;
				user-select: none;

				&.active {
					cursor: default;
					color: #000;
					border-bottom: 1px solid black;
				}
			}
		}
	}

	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 52px;
	background: rgba(250, 250, 252, 0.8);
	-webkit-transition-property: background-color, -webkit-backdrop-filter;
	transition-property: background-color, -webkit-backdrop-filter;
	transition-property: background-color, backdrop-filter;
	transition-property: background-color, backdrop-filter,
		-webkit-backdrop-filter;
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);
	transition: background 0.24s cubic-bezier(0.28, 0.11, 0.32, 1);
	border-bottom: 1px solid #c4c4c4;
	animation: ${fadeInDown} 0.8s cubic-bezier(0.42, 0, 0.58, 1) forwards;

	@media screen and (max-width: 800px) {
		height: 48px;
	}
`;

const StyledButton = styled.button<{ isMobile?: boolean; isWhite?: boolean }>`
	background: ${({ isWhite }) => (isWhite ? 'transparent' : '#000')};
	color: ${({ isWhite }) => (isWhite ? '#000' : '#fff')};
	padding: 3px 10px;
	border-radius: 10px;
	font-size: 12px;
	font-family: 'SF-Regular';
	border: none;
	height: 24px;
	cursor: pointer;
	border: 1px solid #000;
	margin-right: ${({ isWhite }) => (isWhite ? '12px' : '0')};

	&:hover {
		background: ${({ isWhite }) => (isWhite ? 'transparent' : '#303030')};
	}

	@media screen and (min-width: 700px) {
		display: ${({ isMobile }) => isMobile && 'none'};
	}
`;
