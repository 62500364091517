import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';
import { AWP } from './AWP';
import { buildMsg } from './buildMsg';
import { relocate } from '../Header';
import { invertColors } from '../../styles/animations';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/language';
const cart = require('../../assets/research/cart.png');
const barcode = require('../../assets/research/barcode.gif');

type CartProps = {
	counter: number;
	eurusd: boolean;
	setEurusd: () => void;
	usdcad: boolean;
	setUsdcad: () => void;
	usdjpy: boolean;
	setUsdjpy: () => void;
	gbpusd: boolean;
	setGbpusd: () => void;
	usdchf: boolean;
	setUsdchf: () => void;
	audusd: boolean;
	setAudusd: () => void;
	xauusd: boolean;
	setXauusd: () => void;
	xagusd: boolean;
	setXagusd: () => void;
	oilusd: boolean;
	setOilusd: () => void;
	spx500: boolean;
	setSpx500: () => void;
	dj30: boolean;
	setDj30: () => void;
	nas100: boolean;
	setNas100: () => void;
	ger40: boolean;
	setGer40: () => void;
};

export const Cart: FC<CartProps> = ({
	counter,
	eurusd,
	usdcad,
	usdjpy,
	gbpusd,
	usdchf,
	audusd,
	setEurusd,
	setUsdcad,
	setUsdjpy,
	setGbpusd,
	setUsdchf,
	setAudusd,
	xauusd,
	xagusd,
	oilusd,
	setXauusd,
	setXagusd,
	setOilusd,
	spx500,
	dj30,
	nas100,
	ger40,
	setSpx500,
	setDj30,
	setNas100,
	setGer40,
}) => {
	const [isCartOpen, setIsCartOpen] = useState(false);
	const lang = useLanguage();

	const sendMessage = () =>
		relocate(
			`https://t.me/gsclub_manager?text=${buildMsg(
				eurusd,
				usdcad,
				usdjpy,
				gbpusd,
				usdchf,
				audusd,
				xauusd,
				xagusd,
				oilusd,
				spx500,
				dj30,
				nas100,
				ger40
			)}`
		);

	useEffect(() => {
		if (!counter) setIsCartOpen(false);
	}, [counter]);

	useEffect(() => {
		if (isCartOpen) document.body.style.overflow = 'hidden';
		if (!isCartOpen) document.body.style.overflow = 'auto';
	}, [isCartOpen]);

	return (
		<>
			{!!counter && (
				<Wrap
					onClick={() => {
						setIsCartOpen(!isCartOpen);
					}}
				>
					<img src={cart} alt="cart" />
				</Wrap>
			)}
			<Modal isOpen={isCartOpen} onClick={() => setIsCartOpen(false)}>
				<Window onClick={(event) => event.stopPropagation()}>
					<div>
						<section>
							<AWP />
						</section>
						<p>© "GS CLUB"</p>
					</div>
					<div>
						<div>
							{eurusd && (
								<p onClick={setEurusd}>
									<span>
										<span>x </span>
										EURUSD 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{usdcad && (
								<p onClick={setUsdcad}>
									<span>
										<span>x </span>
										USDCAD 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{usdjpy && (
								<p onClick={setUsdjpy}>
									<span>
										<span>x </span>
										USDJPY 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{gbpusd && (
								<p onClick={setGbpusd}>
									<span>
										<span>x </span>
										GBPUSD 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{usdchf && (
								<p onClick={setUsdchf}>
									<span>
										<span>x </span>
										USDCHF 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{audusd && (
								<p onClick={setAudusd}>
									<span>
										<span>x </span>
										AUDUSD 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{xauusd && (
								<p onClick={setXauusd}>
									<span>
										<span>x </span>
										XAUUSD 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{xagusd && (
								<p onClick={setXagusd}>
									<span>
										<span>x </span>
										XAGUSD 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{oilusd && (
								<p onClick={setOilusd}>
									<span>
										<span>x </span>
										Crude Oil 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{spx500 && (
								<p onClick={setSpx500}>
									<span>
										<span>x </span>
										SPX500 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{dj30 && (
								<p onClick={setDj30}>
									<span>
										<span>x </span>
										DJ30 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{nas100 && (
								<p onClick={setNas100}>
									<span>
										<span>x </span>
										NAS100 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							{ger40 && (
								<p onClick={setGer40}>
									<span>
										<span>x </span>
										GER40 5 Year Research
									</span>
									<span>$20</span>
								</p>
							)}
							<p id="instruction">
								<span>
									<span>x </span>
									{LANG_TEXT[lang].research.list.text5}
								</span>
								<span>FREE</span>
							</p>
						</div>
					</div>
					<div>
						<h1>
							<span>TOTAL ${counter * 20}</span>
							<span></span>
						</h1>
						<button onClick={sendMessage}>Buy</button>
					</div>
					<article>
						<img src={barcode} alt="barcode" />
					</article>
				</Window>
			</Modal>
		</>
	);
};

const Wrap = styled.div`
	position: fixed;
	z-index: 10000000;
	width: 50px;
	height: 50px;
	bottom: 30px;
	right: 30px;
	background: white;
	border-radius: 10px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-box-shadow: 0px 0px 16px -10px rgba(0, 0, 0, 1);
	-moz-box-shadow: 0px 0px 16px -10px rgba(0, 0, 0, 1);
	box-shadow: 0px 0px 16px -10px rgba(0, 0, 0, 1);
	cursor: pointer;
	transition: 0.3s;
	animation: ${invertColors} 5s infinite;

	img {
		width: 100%;
	}

	&:hover {
		transform: scale(1.02);
	}
`;

const Modal = styled.div<{ isOpen: boolean }>`
	display: ${({ isOpen }) => isOpen && 'none'};
	z-index: ${({ isOpen }) => (isOpen ? 10000 : -1)};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0f0f0f46;
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;

	* {
		user-select: none;
	}
`;

const Window = styled.div`
	background: #fff;
	min-width: 300px;
	min-height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 15px;

	button {
		padding: 0px 10px;
	}

	> div {
		width: 100%;
		display: flex;
		justify-content: space-between;

		&:nth-of-type(2) {
			margin: 30px 0;
		}

		> div {
			width: 100%;

			p {
				display: flex;
				justify-content: space-between;
				width: 100%;
				margin-top: 5px;
				cursor: pointer;

				span > span {
					display: inline-block;
					transform: translateY(-1px);
					width: 30px;
				}

				&:hover {
					text-decoration: line-through;
				}

				&#instruction:hover {
					cursor: default;
					text-decoration: none;
				}
			}
		}

		img {
			width: 150px;
		}

		section {
			width: 70px;

			svg {
				width: 100%;
			}
		}
	}
	article {
		display: flex;
		justify-content: center;
		width: 100%;

		img {
			width: 150px;
		}
	}
`;
