import React, { useEffect } from 'react';
import styled from 'styled-components';
import { fadeInUp } from '../../styles/animations';
import { scrollToPlans } from '../Header';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/language';
const blackLogo = require('../../assets/BlackLogo.png');
const iphone = require('../../assets/iphone.png');

export const Start = () => {
	const lang = useLanguage();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper>
			<section>
				<Logo>
					<div>
						<img src={blackLogo} alt="Logo" />
					</div>
					<p>Club</p>
				</Logo>
				<h1>
					{LANG_TEXT[lang].start.text1}
					<br />
					{LANG_TEXT[lang].start.text2}
				</h1>
				<Button onClick={() => scrollToPlans()}>
					{LANG_TEXT[lang].start.text3}
				</Button>
			</section>
			<ImgWrap>
				<img src={iphone} alt="phone" />
			</ImgWrap>
		</Wrapper>
	);
};

const ImgWrap = styled.section`
	padding-top: 50px;
	width: 520px;

	img {
		animation: ${fadeInUp} 0.8s cubic-bezier(0.42, 0, 0.58, 1) forwards;
		width: 100%;
	}

	@media screen and (max-width: 1067px) {
		padding-top: 150px;
		width: 360px;
	}

	@media screen and (max-width: 734px) {
		padding-top: 100px;
		width: 288px;
	}

	@media screen and (max-width: 474px) {
		padding-top: 50px;
	}
`;

const Button = styled.button`
	margin-top: 35px;
	font-family: 'SF-Regular';
	color: #fff;
	background: #1d1d1f;
	border: none;
	font-size: 17px;
	padding: 11px 21px;
	cursor: pointer;
	border-radius: 980px;
	letter-spacing: -0.022em;
	text-align: center;

	&:hover {
		background: #2c2c2e;
	}
`;

const Logo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(0.95);

	> div {
		margin-top: 3px;
		width: 27px;
		height: 27px;

		img {
			width: 100%;
		}
	}

	p {
		margin-left: 3px;
		font-size: 40px;
		font-family: 'SF-Regular';
	}

	@media screen and (max-width: 1250px) {
		transform: scale(0.9);
	}
	@media screen and (max-width: 734px) {
		transform: scale(0.8);
	}
	@media screen and (max-width: 648px) {
		transform: scale(0.7);
	}
`;

const Wrapper = styled.div`
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	overflow: hidden;
	padding: 240px 20px 0;

	section {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		z-index: 2;
	}

	h1 {
		margin-top: 5px;
		text-align: center;
		font-size: 87px;
		line-height: 1.04167;
		letter-spacing: -0.015em;
	}

	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(7%, #e9bcf2),
		color-stop(#f8b7c5),
		color-stop(#fec49a),
		color-stop(#ffd493),
		color-stop(80%, #ffedbe),
		to(#fff7e0)
	);
	background: -webkit-linear-gradient(
		left,
		#e9bcf2 7%,
		#f8b7c5,
		#fec49a,
		#ffd493,
		#ffedbe 80%,
		#fff7e0
	);
	background: linear-gradient(
		90deg,
		#e9bcf2 7%,
		#f8b7c5,
		#fec49a,
		#ffd493,
		#ffedbe 80%,
		#fff7e0
	);

	&:before {
		z-index: 1;
		content: '';
		display: block;
		position: absolute;
		left: 0px;
		bottom: 0px;
		width: 100%;
		height: 100%;
		background: -webkit-radial-gradient(
				50% 100%,
				circle,
				#d1f0bc 25%,
				rgba(209, 240, 188, 0) 55%
			),
			-webkit-radial-gradient(at bottom left, #faf9fd 10%, rgba(
							250,
							249,
							253,
							0
						)
						30%),
			-webkit-radial-gradient(at bottom right, #fbfefa 25%, rgba(
							251,
							254,
							250,
							0
						)
						40%);
		background: radial-gradient(
				circle at 50% 100%,
				#d1f0bc 25%,
				rgba(209, 240, 188, 0) 55%
			),
			radial-gradient(at bottom left, #faf9fd 10%, rgba(250, 249, 253, 0) 30%),
			radial-gradient(at bottom right, #fbfefa 25%, rgba(251, 254, 250, 0) 40%);
	}

	@media screen and (max-width: 1250px) {
		padding-top: 200px;

		h1 {
			font-size: 55px;
			line-height: 1.05;
			letter-spacing: -0.015em;
		}
	}

	@media screen and (max-width: 734px) {
		h1 {
			font-size: 37px;
			line-height: 1.08349;
			letter-spacing: -0.003em;
		}

		background: -webkit-linear-gradient(
			350deg,
			#e9bcf2,
			#fdc7b3,
			#fedb9a,
			#fff2d0 85%
		);
		background: linear-gradient(100deg, #e9bcf2, #fdc7b3, #fedb9a, #fff2d0 85%);

		&:before {
			background-size: 100% auto;
			background: -webkit-radial-gradient(
					55% 80%,
					circle,
					#d1f0bc 0%,
					rgba(209, 240, 188, 0) 40%
				),
				-webkit-radial-gradient(20% 100%, circle, #faf9fd 15%, rgba(
								250,
								249,
								253,
								0
							)
							50%),
				-webkit-radial-gradient(at bottom right, #fbfefa 15%, rgba(
								251,
								254,
								250,
								0
							)
							40%);
			background: radial-gradient(
					circle at 55% 80%,
					#d1f0bc 0%,
					rgba(209, 240, 188, 0) 40%
				),
				radial-gradient(
					circle at 20% 100%,
					#faf9fd 15%,
					rgba(250, 249, 253, 0) 50%
				),
				radial-gradient(
					at bottom right,
					#fbfefa 15%,
					rgba(251, 254, 250, 0) 40%
				);
		}
	}

	@media screen and (max-width: 648px) {
		h1 {
			font-size: 38px;
			line-height: 1.2;
		}
	}

	@media screen and (max-width: 415px) {
		h1 {
			font-size: 38px;
			line-height: 1.2;
		}
	}
`;
