import React, { FC } from 'react';
import { AWP } from './AWP';
import styled from 'styled-components';
import { Plus } from './Plus';
import { Tick } from './Tick';
import { LANG_TEXT } from '../../lang/language';
import { useLanguage } from '../../hooks/useLanguage';

type CardProps = {
	ticker: string;
	category: string;
	bgColor: string;
	textColor: string;
	image: string;
	isInCart: boolean;
	onClick: () => void;
	imgCustomWidth?: number;
};
export const Card: FC<CardProps> = ({
	ticker,
	category,
	bgColor,
	textColor,
	image,
	isInCart,
	onClick,
	imgCustomWidth,
}) => {
	const lang = useLanguage();

	return (
		<Wrap bgColor={bgColor} textColor={textColor} onClick={onClick}>
			<Top>
				<div>
					<h1>{ticker}</h1>
					<p>{LANG_TEXT[lang].research.list.text4}</p>
				</div>
				<ImgWrap width={imgCustomWidth}>
					<img src={image} alt="ticker illustration" />
				</ImgWrap>
			</Top>
			<Bottom>
				<ImgWrap width={30}>
					{isInCart ? <Tick color={textColor} /> : <Plus color={textColor} />}
				</ImgWrap>
				<Logo>
					<ImgWrap width={40}>
						<AWP color={textColor} />
					</ImgWrap>
					<Divider color={textColor} />
					<p>{category}</p>
				</Logo>
			</Bottom>
		</Wrap>
	);
};

const Wrap = styled.div<{ bgColor: string; textColor: string }>`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: space-between;
	width: 350px;
	height: 200px;
	border-radius: 10px;
	background: ${({ bgColor }) => bgColor};
	padding: 30px 25px 16px 20px;
	cursor: pointer;
	transition: 0.25s;

	&:hover {
		-webkit-box-shadow: 0px 0px 24px -9px rgba(0, 0, 0, 1);
		-moz-box-shadow: 0px 0px 24px -9px rgba(0, 0, 0, 1);
		box-shadow: 0px 0px 24px -9px rgba(0, 0, 0, 1);
		transform: scale(1.01);
	}

	@media screen and (max-width: 405px) {
		width: 100%;
	}

	h1,
	p {
		text-align: left;
		color: ${({ textColor }) => textColor};
	}

	h1 {
		font-size: 44px;
	}

	p {
		letter-spacing: 0.5px;
	}

	* {
		user-select: none;
	}
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
`;

const ImgWrap = styled.div<{ width?: number }>`
	display: flex;
	align-items: flex-end;
	width: ${({ width }) => (width ? width + 'px' : '90px')};

	img,
	svg {
		object-fit: contain;
		width: 100%;
	}
`;

const Logo = styled.div`
	display: flex;
	align-items: center;
`;

const Divider = styled.div<{ color: string }>`
	width: 1px;
	margin: 0 8px;
	height: 22px;
	background: ${({ color }) => color};
`;
