import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router';
import './index.css';
import './styles/animations.css';
import { GlobalStyle } from './styles/global';
import { Start } from './components/Start';
import { Main } from './components/Main';
import { Header } from './components/Header';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Plans } from './components/Plans';
import { Footer } from './components/Footer';
import { Research } from './components/Research';
import { Redirect } from './components/Redirect';

function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<ParallaxProvider>
					<Header />
					<GlobalStyle />
					<Routes>
						<Route
							path="/"
							element={
								<>
									<Start />
									<Main />
									<Plans />
								</>
							}
						/>
						<Route
							path="/ru"
							element={
								<>
									<Start />
									<Main />
									<Plans />
								</>
							}
						/>
						<Route path="/research" element={<Research />} />
						<Route path="/ru/research" element={<Research />} />
						<Route
							path="/camp"
							element={
								<Redirect link="https://t.me/fixyourself777?text=TradingCamp" />
							}
						/>
					</Routes>
					<Footer />
				</ParallaxProvider>
			</div>
		</BrowserRouter>
	);
}

export default App;
