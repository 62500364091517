import React, { FC } from 'react';
import styled from 'styled-components';
import { useLanguage } from '../../hooks/useLanguage';
import { LANG_TEXT } from '../../lang/language';
const plus = require('../../assets/PlusBlack.png');

type PropBackProps = {
	setOpen: (value: boolean) => void;
};

export const PropBack: FC<PropBackProps> = ({ setOpen }) => {
	const lang = useLanguage();
	return (
		<Wrap>
			<Heading>
				{LANG_TEXT[lang].cards.prop.heading}
				<br />
				{LANG_TEXT[lang].cards.prop.heading2}
			</Heading>
			<p>{LANG_TEXT[lang].cards.prop.text}</p>
			<div onClick={() => setOpen(false)}>
				<img src={plus} alt="plus" />
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	height: 820px;
	width: 565px;
	padding-left: 95px;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: center;

	> p {
		width: 390px;
		margin-top: 22px;
		font-size: 20px;
		line-height: 1.381;
		font-weight: 400;
		letter-spacing: 0.011em;
		font-family: 'SF-Regular';
	}

	> div {
		position: absolute;
		bottom: 30px;
		right: 30px;
		width: 40px;

		img {
			transform: rotate(45deg);
			cursor: pointer;
			width: 100%;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	@media screen and (max-width: 1170px) {
		height: 600px;
		width: 400px;
		padding-left: 60px;

		p {
			width: 300px;
			font-size: 13px;
		}
	}
`;

const Heading = styled.h2`
	font-size: 21px;
	line-height: 1.381;
	font-weight: 400;
	letter-spacing: 0.015em;
	color: #656565;
	font-family: 'SF-Bold';

	@media screen and (max-width: 1170px) {
		font-size: 13px;
	}
`;
